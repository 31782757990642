import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-1'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-1',  fill: '#d0be5b' }, 0)
        .add({ targets: '.large-select-0',  fill: '#3c3c3c' }, 0)

    },[])



    //render() {
        return (
          <div className="loaderContainer" >
            <div className="small-loading-icon-container" >
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 244.06 40.8">
                    <path className="large-select-1"
                          d="M126.8,35.6c.6-.5,2.4-4.4,2.9-5.5l2.7-5.8c.4.5,1.4,8.8,1.5,9.9.1.9-.2.9-.4,1.3a27.15,27.15,0,0,0,2.9.1c.6,0,2.4.3,2.8-.2-.8-.3-1.6-.1-2.2-.6-.6-.7-1.2-5.9-1.3-7-.2-1.6-.5-3.2-.7-4.8-.2-1-.3-4.1-1-4.6-1.1.6-5.5,11.9-6.6,13.1l-4.9-10c-.3-.6-.5-1.1-.8-1.7-.5-1-.4-1.3-1.2-1.4-.7,2.3-1.1,6.6-1.5,9.3-.2,1.6-.5,3.1-.7,4.6-.2,1.3-.2,2.5-1.2,2.9-.7.2-.6-.1-.9.4h5.1c.3-.1.1,0,.3-.2-2-.8-2.3,1.5-1.3-5.8.1-.6.5-4.3.7-4.8l.1-.2a36.73,36.73,0,0,0,2.7,5.7c.5.9,1,1.8,1.4,2.7a8.22,8.22,0,0,0,1.6,2.6"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M158.9,27.9c.6-5.7-3.2-9-8.4-9.3-7.8-.4-11.4,6.3-9.3,12,1.3,3.5,4.2,5.2,7.9,5.4a8.89,8.89,0,0,0,9.8-8.1m-11.1-8.1a6.19,6.19,0,0,1,6.9,3.2,11.91,11.91,0,0,1,.6,8.5c-1.3,4-5.7,4.4-8.7,2.1-2.7-2-3.4-7.1-2.3-10.5A4.7,4.7,0,0,1,147.8,19.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M218.4,18.9c-.7,0-1.7-.1-2.1.2,1.9,1,2.2-1.7,2.3,5,.1,2.4.1,4.9.2,7.3l-9.6-9.7-.8-.8a6,6,0,0,1-.8-.8c-.4-.5-.9-1.3-1.7-1.4-.4,1.3-.2,7.6-.3,9.4-.1,8,.4,6.7-2.1,7.4.3.3,0,.2.6.3h4.2c1.2,0,1.1.1,1.3-.2-1.2-.5-1.8.4-2.1-1.5-.2-1-.3-9.2-.3-10.5.4.2.1,0,.3.2l.2.2.4.4,8.5,8.8c.6.6,2.5,2.8,3.3,2.9.4-.6.4-11.8.4-14,.1-3.5.5-2.2,1.9-2.8-.2-.2.2-.1-.4-.2Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M165.8,22.8c0-4.5.4-2.9,1.9-3.6-.5-.5-2.5-.2-3.5-.2s-2.9-.3-3.5.2c1.6.8,2.2-1.2,2.2,3.6,0,6.3-1.1,13.2,6.7,13.2,7.4,0,6.9-6.2,6.9-11.1,0-7.3.1-4.9,1.9-5.8-.5-.5-2.2-.2-3-.2H173c-.6.1-.5.2-.5.3,2.3.5,2.2-.8,2.2,5.5,0,2.3.3,6.6-.9,8.4-1.4,2.2-8.1,3.9-8.1-4.7Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M229.1,18.9h-2.3c-.9,0-1.5-.1-1.9.3,1.9.6,2.3-.7,2.3,3.6,0,1.5.3,10.9-.5,12.4l-1.4.2c.3.5,1.8.2,2.4.2,1.3,0,7.5.3,7.8.1a4.59,4.59,0,0,0,.5-3.3c-.4.2-.1-.2-.3.5-.4,1.3-.5,1.7-3,1.7h-.1c-2.5,0-2.6-.4-2.6-2.8V27.3a19,19,0,0,1,3.4.1c1.3.2,1.1.8,1.2,1.3s-.1.3.3.5a13.7,13.7,0,0,0,.4-3.6c-1.2.3.6.8-5.3.7V20c7,0,4.1,1.1,5.3,2a15.54,15.54,0,0,0,.4-3.2c-2.2.1-4.4.1-6.6.1"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M239.9,35.3a8.41,8.41,0,0,0,4.5.7c5.3-.5,5.9-5.9,2.7-9a22.57,22.57,0,0,0-2.9-2.3c-3.4-2.5-1.5-5.6,1.7-4.9,2,.4,2,2.3,2.3,2.8.3-.2.1.2.3-.2l.1-3.2c-6-2.2-12.4,2.9-5.2,8.9a14.13,14.13,0,0,1,2.9,2.5,3,3,0,0,1-.2,3.8,3.82,3.82,0,0,1-4,.4,3.12,3.12,0,0,1-1.6-2.4l-.2-.9c0-.1,0-.1-.1-.1-.5.4-.5,3-.3,3.9"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M181.4,35.2a7.44,7.44,0,0,0,4.5.7c5.3-.5,5.9-5.8,2.8-8.9-1.8-1.8-5.2-3.1-4.7-5.5.2-1.4,1.7-2.1,3.4-1.8,2,.4,2.1,2.4,2.3,2.8.1,0,.3.2.4-.7V19c-3.1-1-6.7-.4-7.9,1.9a4.66,4.66,0,0,0,.1,4.3c1.3,2.5,4.2,3.5,5.3,5.2a3,3,0,0,1,0,3.8,3.77,3.77,0,0,1-4,.5c-1.2-.6-1.6-1.8-1.8-3.4-.5.3-.4.7-.4,1.9a13.33,13.33,0,0,0,0,2"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M105.4,32.4c-1.1.4.8,2.5-4.4,2.2-1.2-.1-1.9-.1-2.1-1.2s-.3-12-.1-13.1c.2-1.3,1.1-.9,2.1-1.1-.4-.5-2.4-.3-3.5-.3-.7,0-3.2-.2-3.5.2.6.3,1.5,0,1.9.7s.2,11,.2,12.5c-.1,3.6-.6,2.7-1.9,3.1.3.4,4.6.3,5.5.3,1.2,0,4.4.3,5.4,0,.3-.4.8-2.7.4-3.3"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M200.6,19.1c-.5-.4-4.8-.2-5.6-.2-.6,0-.7-.1-1,.2.8.4,1.5-.2,1.8,1,.2.8.1,9.3.1,10.5,0,5.9-.3,4.2-1.9,4.8.2.3-.2.1.4.2h6.2c.5-.1.5-.2.5-.3-1.2-.2-2.3.2-2.4-1.3l-.1-13.2c.2-2.1.9-1,2-1.7"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M112.2,23.4c0-5.4.3-3.6,1.8-4.3-.4-.5-3.8-.2-5.1-.2H108c-.5.1-.5.2-.5.2.9.3,1.6-.1,1.8.9.2.8.1,8.7.1,9.9,0,1.5.2,4.1-.4,5.1l-1.5.3c.3.3.5.2,1,.2H114c.5,0,.4,0,.6-.2-1.1-.4-2.2.3-2.4-1.2-.1-1.1,0-9.1,0-10.7"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M74.5,22.8c1.1,0,2.3-1.3,3.2-1,.3.7.3,10.9,0,12.4-.3,1.7-1.8.7-2.8,1.3.4.4,7.8.3,8.2.1-.3-.5-1.3-.1-2-.5-.5-.3-.5-1.1-.5-1.9V18.7c-.6,0-3.9,2.4-4.7,2.9-.5.3-1.2.5-1.4,1.2"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M126.8,35.6c.6-.5,2.4-4.4,2.9-5.5l2.7-5.8c.4.5,1.4,8.8,1.5,9.9.1.9-.2.9-.4,1.3a27.15,27.15,0,0,0,2.9.1c.6,0,2.4.3,2.8-.2-.8-.3-1.6-.1-2.2-.6-.6-.7-1.2-5.9-1.3-7-.2-1.6-.5-3.2-.7-4.8-.2-1-.3-4.1-1-4.6-1.1.6-5.5,11.9-6.6,13.1l-4.9-10c-.3-.6-.5-1.1-.8-1.7-.5-1-.4-1.3-1.2-1.4-.7,2.3-1.1,6.6-1.5,9.3-.2,1.6-.5,3.1-.7,4.6-.2,1.3-.2,2.5-1.2,2.9-.7.2-.6-.1-.9.4h5.1c.3-.1.1,0,.3-.2-2-.8-2.3,1.5-1.3-5.8.1-.6.5-4.3.7-4.8l.1-.2a36.73,36.73,0,0,0,2.7,5.7c.5.9,1,1.8,1.4,2.7a8.22,8.22,0,0,0,1.6,2.6"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M126.8,35.6c.6-.5,2.4-4.4,2.9-5.5l2.7-5.8c.4.5,1.4,8.8,1.5,9.9.1.9-.2.9-.3,1.3a27.15,27.15,0,0,0,2.9.1c.6,0,2.4.3,2.8-.2-.8-.3-1.7-.1-2.2-.6-.6-.7-1.2-5.9-1.3-7-.2-1.6-.5-3.2-.7-4.8-.2-1-.3-4.1-1-4.6-1.1.6-5.5,11.9-6.6,13.1l-4.9-10c-.3-.6-.5-1.1-.8-1.7-.5-1-.4-1.3-1.2-1.4-.7,2.3-1.1,6.6-1.5,9.3-.2,1.6-.5,3.1-.7,4.6-.2,1.3-.2,2.5-1.2,2.9-.7.2-.6-.1-.9.4h5.1c.3-.1.1,0,.3-.2-2-.8-2.3,1.5-1.3-5.8.1-.6.5-4.3.7-4.8l.1-.2a36.73,36.73,0,0,0,2.7,5.7c.5.9,1,1.8,1.4,2.7A6.6,6.6,0,0,0,126.8,35.6Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M59.6,35.6c.7-.4,2.4-4.5,2.9-5.6l2.7-5.7a.77.77,0,0,1,.2.3l1.1,8.2c.3,2.2.4,1.7-.2,2.6a20.78,20.78,0,0,0,4.5.3c.8,0,.9.1,1.2-.2-.7-.3-1.6,0-2.2-.6-1-1.1-2-15.1-2.9-16.3-.5,0-.5.2-.7.6L63.4,25c-.4.9-2.9,6.2-3.2,6.6-.5-.6-4.3-8.7-4.9-10-.3-.6-.6-1.1-.9-1.7-.6-1.3-.5-1.3-1.1-1.4-.3,1-.6,3.3-.8,4.6S50.9,34,50.6,34.7c-.5,1-1.2.3-1.6,1h4.6c.8,0,.7,0,.8-.2-.9-.3-1,.1-1.8-.6-.3-.5.8-7.5,1-9.1l.2-.9.1-.1c0-.1,0-.1.1-.1l2.7,5.7C57.1,31.3,59,35.2,59.6,35.6Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M158.9,27.9c.6-5.7-3.2-9-8.4-9.3-7.8-.4-11.4,6.3-9.3,12,1.3,3.5,4.2,5.2,7.9,5.4a8.89,8.89,0,0,0,9.8-8.1m-11.1-8.1a6.19,6.19,0,0,1,6.9,3.2,11.91,11.91,0,0,1,.6,8.5c-1.3,4-5.7,4.4-8.7,2.1-2.7-2-3.4-7.1-2.3-10.5A4.7,4.7,0,0,1,147.8,19.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M158.9,27.9c.6-5.7-3.2-9-8.4-9.3-7.8-.4-11.4,6.3-9.3,12,1.3,3.5,4.2,5.2,7.9,5.4C154.7,36.3,158.3,32.8,158.9,27.9Zm-11.1-8.1a6.19,6.19,0,0,1,6.9,3.2,11.91,11.91,0,0,1,.6,8.5c-1.3,4-5.7,4.4-8.7,2.1-2.7-2-3.4-7.1-2.3-10.5A4.7,4.7,0,0,1,147.8,19.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M218.4,18.9c-.7,0-1.7-.1-2.1.2,1.9,1,2.2-1.7,2.3,5,.1,2.4.1,4.9.2,7.3l-9.6-9.7-.8-.8a6,6,0,0,1-.8-.8c-.4-.5-.9-1.3-1.7-1.4-.4,1.3-.2,7.6-.3,9.4-.1,8,.4,6.7-2.1,7.4.3.3,0,.2.6.3h4.2c1.2,0,1.1.1,1.3-.2-1.2-.5-1.8.4-2.1-1.5-.2-1-.3-9.2-.3-10.5.4.2.1,0,.3.2l.2.2.4.4,8.5,8.8c.6.6,2.5,2.8,3.3,2.9.4-.6.4-11.8.4-14,.1-3.5.5-2.2,1.9-2.8-.2-.2.2-.1-.4-.2Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M218.4,18.9c-.7,0-1.7-.1-2.1.2,1.9,1,2.2-1.7,2.3,5,.1,2.4.1,4.9.2,7.3l-9.6-9.7-.8-.8a6,6,0,0,1-.8-.8c-.4-.5-.9-1.3-1.7-1.4-.4,1.3-.2,7.6-.3,9.4-.1,8,.4,6.7-2.1,7.4.3.3,0,.2.6.3h4.2c1.2,0,1.1.1,1.3-.2-1.2-.5-1.8.4-2.2-1.5-.2-1-.3-9.2-.3-10.5.4.2.1,0,.3.2l.2.2.4.4,8.5,8.8c.6.6,2.5,2.8,3.3,2.9.4-.6.4-11.8.4-14,.1-3.5.5-2.2,1.9-2.8-.2-.2.2-.1-.4-.2Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M165.8,22.8c0-4.5.4-2.9,1.9-3.6-.5-.5-2.5-.2-3.5-.2s-2.9-.3-3.5.2c1.6.8,2.2-1.2,2.2,3.6,0,6.3-1.1,13.2,6.7,13.2,7.4,0,6.9-6.2,6.9-11.1,0-7.3.1-4.9,1.9-5.8-.5-.5-2.2-.2-3-.2H173c-.6.1-.5.2-.5.3,2.3.5,2.2-.8,2.2,5.5,0,2.3.3,6.6-.9,8.4-1.4,2.2-8.1,3.9-8.1-4.7Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M165.8,22.8c0-4.5.4-2.9,1.9-3.6-.5-.5-2.5-.2-3.5-.2s-2.9-.3-3.4.2c1.6.8,2.2-1.2,2.2,3.6,0,6.3-1.1,13.2,6.7,13.2,7.4,0,6.9-6.2,6.9-11.1,0-7.3.1-4.9,1.9-5.8-.5-.5-2.2-.2-3.1-.2H173c-.6.1-.5.2-.5.3,2.3.5,2.2-.8,2.2,5.5,0,2.3.3,6.6-.9,8.4-1.4,2.2-8.1,3.9-8.1-4.7V22.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M229.1,18.9h-2.3c-.9,0-1.5-.1-1.9.3,1.9.6,2.3-.7,2.3,3.6,0,1.5.3,10.9-.5,12.4l-1.4.2c.3.5,1.8.2,2.4.2,1.3,0,7.5.3,7.8.1a4.59,4.59,0,0,0,.5-3.3c-.4.2-.1-.2-.3.5-.4,1.3-.5,1.7-3,1.7h-.1c-2.5,0-2.6-.4-2.6-2.8V27.3a19,19,0,0,1,3.4.1c1.3.2,1.1.8,1.2,1.3s-.1.3.3.5a13.7,13.7,0,0,0,.4-3.6c-1.2.3.6.8-5.3.7V20c7,0,4.1,1.1,5.3,2a15.54,15.54,0,0,0,.4-3.2c-2.2.1-4.4.1-6.6.1"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M229.1,18.9h-2.3c-.9,0-1.5-.1-1.9.3,1.9.6,2.3-.7,2.3,3.6,0,1.5.3,10.9-.5,12.4l-1.4.2c.3.5,1.8.2,2.4.2,1.3,0,7.5.3,7.8.1a4.59,4.59,0,0,0,.5-3.3c-.4.2-.1-.2-.4.5-.4,1.3-.5,1.7-3,1.7h-.1c-2.5,0-2.6-.4-2.6-2.8V27.3a19,19,0,0,1,3.4.1c1.3.2,1.1.8,1.2,1.3s-.1.3.3.5a13.7,13.7,0,0,0,.4-3.6c-1.2.3.6.8-5.3.7V20c7,0,4.1,1.1,5.3,2a15.54,15.54,0,0,0,.4-3.2C233.5,18.9,231.3,18.9,229.1,18.9Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M239.9,35.3a8.41,8.41,0,0,0,4.5.7c5.3-.5,5.9-5.9,2.7-9a22.57,22.57,0,0,0-2.9-2.3c-3.4-2.5-1.5-5.6,1.7-4.9,2,.4,2,2.3,2.3,2.8.3-.2.1.2.3-.2l.1-3.2c-6-2.2-12.4,2.9-5.2,8.9a14.13,14.13,0,0,1,2.9,2.5,3,3,0,0,1-.2,3.8,3.82,3.82,0,0,1-4,.4,3.12,3.12,0,0,1-1.6-2.4l-.2-.9c0-.1,0-.1-.1-.1-.5.4-.5,3-.3,3.9"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M239.9,35.3a8.41,8.41,0,0,0,4.5.7c5.3-.5,5.9-5.9,2.7-9a22.57,22.57,0,0,0-2.9-2.3c-3.4-2.5-1.5-5.6,1.7-4.9,2,.4,2,2.3,2.3,2.8.3-.2.1.2.3-.2l.1-3.2c-6-2.2-12.4,2.9-5.2,8.9a14.13,14.13,0,0,1,2.9,2.5,3,3,0,0,1-.2,3.8,3.82,3.82,0,0,1-4,.4,3.12,3.12,0,0,1-1.6-2.4l-.2-.9c0-.1,0-.1-.1-.1C239.7,31.8,239.7,34.4,239.9,35.3Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M181.4,35.2a7.44,7.44,0,0,0,4.5.7c5.3-.5,5.9-5.8,2.8-8.9-1.8-1.8-5.2-3.1-4.7-5.5.2-1.4,1.7-2.1,3.4-1.8,2,.4,2.1,2.4,2.3,2.8.1,0,.3.2.4-.7V19c-3.1-1-6.7-.4-7.9,1.9a4.66,4.66,0,0,0,.1,4.3c1.3,2.5,4.2,3.5,5.3,5.2a3,3,0,0,1,0,3.8,3.77,3.77,0,0,1-4,.5c-1.2-.6-1.6-1.8-1.8-3.4-.5.3-.4.7-.4,1.9a13.33,13.33,0,0,0,0,2"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M181.4,35.2a7.44,7.44,0,0,0,4.5.7c5.3-.5,5.9-5.8,2.8-8.9-1.8-1.8-5.2-3.1-4.7-5.5.2-1.4,1.7-2.1,3.4-1.8,2,.4,2.1,2.4,2.3,2.8.1,0,.3.2.4-.7V19c-3.1-1-6.7-.4-7.9,1.9a4.39,4.39,0,0,0,.1,4.3c1.3,2.5,4.2,3.5,5.3,5.2a3,3,0,0,1,0,3.8,3.77,3.77,0,0,1-4,.5c-1.2-.6-1.6-1.8-1.8-3.4-.5.3-.4.7-.4,1.9A13.33,13.33,0,0,0,181.4,35.2Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M105.4,32.4c-1.1.4.8,2.5-4.4,2.2-1.2-.1-1.9-.1-2.1-1.2s-.3-12-.1-13.1c.2-1.3,1.1-.9,2.1-1.1-.4-.5-2.4-.3-3.5-.3-.7,0-3.2-.2-3.5.2.6.3,1.5,0,1.9.7s.2,11,.2,12.5c-.1,3.6-.6,2.7-1.9,3.1.3.4,4.6.3,5.5.3,1.2,0,4.4.3,5.4,0,.3-.4.8-2.7.4-3.3"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M105.5,32.4c-1.1.4.8,2.5-4.4,2.2-1.2-.1-1.9-.1-2.1-1.2s-.3-11.9-.1-13.1,1.1-.9,2.1-1.1c-.4-.5-2.4-.3-3.5-.3-.7,0-3.2-.2-3.5.2.6.3,1.5,0,1.9.7s.2,11,.2,12.5c-.1,3.6-.6,2.7-1.9,3.1.3.4,4.6.3,5.5.3,1.2,0,4.4.3,5.4,0C105.3,35.3,105.8,33,105.5,32.4Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M200.6,19.1c-.5-.4-4.8-.2-5.6-.2-.6,0-.7-.1-1,.2.8.4,1.5-.2,1.8,1,.2.8.1,9.3.1,10.5,0,5.9-.3,4.2-1.9,4.8.2.3-.2.1.4.2h6.2c.5-.1.5-.2.5-.3-1.2-.2-2.3.2-2.4-1.3l-.1-13.2c.2-2.1.9-1,2-1.7"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M200.6,19.1c-.5-.4-4.8-.2-5.6-.2-.6,0-.7-.1-1,.2.8.4,1.5-.2,1.8,1,.2.8.1,9.3.1,10.5,0,5.9-.3,4.2-1.9,4.8.2.3-.2.1.4.2h6.2c.5-.1.5-.2.5-.3-1.2-.2-2.3.2-2.4-1.3l-.1-13.2C198.8,18.7,199.5,19.8,200.6,19.1Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M112.2,23.4c0-5.4.3-3.6,1.8-4.3-.4-.5-3.8-.2-5.1-.2H108c-.5.1-.5.2-.5.2.9.3,1.6-.1,1.8.9.2.8.1,8.7.1,9.9,0,1.5.2,4.1-.4,5.1l-1.5.3c.3.3.5.2,1,.2H114c.5,0,.4,0,.6-.2-1.1-.4-2.2.3-2.4-1.2-.1-1.1,0-9.1,0-10.7"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M112.2,23.4c0-5.4.3-3.5,1.8-4.3-.4-.5-3.8-.2-5.1-.2H108c-.5.1-.5.2-.5.2.9.3,1.6-.1,1.8.9.2.8.1,8.7.1,9.9,0,1.5.2,4.1-.4,5.1l-1.5.3c.3.3.5.2,1,.2H114c.5,0,.4,0,.6-.2-1.1-.4-2.2.3-2.4-1.2C112.1,33,112.2,25,112.2,23.4Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M74.5,22.8c1.1,0,2.3-1.3,3.2-1,.3.7.3,10.9,0,12.4-.3,1.7-1.8.7-2.8,1.3.4.4,7.8.3,8.2.1-.3-.5-1.3-.1-2-.5-.5-.3-.5-1.1-.5-1.9V18.7c-.6,0-3.9,2.4-4.7,2.9-.5.3-1.2.5-1.4,1.2"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M74.5,22.8c1.1,0,2.3-1.3,3.2-1,.3.7.3,10.9,0,12.4-.3,1.7-1.8.7-2.8,1.3.4.4,7.8.3,8.2.1-.3-.5-1.3-.1-2-.5-.5-.3-.5-1.1-.5-1.9V18.7c-.6,0-3.9,2.4-4.7,2.9C75.4,21.9,74.7,22.1,74.5,22.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,13.7c1.1-.3,2.7-1.3,3.5-1.4a30.11,30.11,0,0,1-.9,9.5c-1.1,3.9-2.1,4.5-3.3,7h2.8a17.91,17.91,0,0,0,1.2-2.4,15.78,15.78,0,0,1,.9-2.6,25.58,25.58,0,0,1,.9,2.6c.3.8,1,1.7,1.2,2.4h2.9c-1.1-2.4-2.2-3.1-3.3-7.1a33,33,0,0,1-.9-9.5c1.1.2,2.3,1.2,3.6,1.4a8.45,8.45,0,0,0-1.5-1.8,8.86,8.86,0,0,1-2.2-4.3,16.65,16.65,0,0,0-.6-2.6c-.8,1.5,0,4.2-3.6,7.7a3.38,3.38,0,0,0-.7,1.1"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M9.9,28.8h2.6a30.52,30.52,0,0,0-3-11.2,4.59,4.59,0,0,1-.7-1.4c1.5,0,2.4.9,3.1,1-.2-.2-.3-.5-.5-.7l-.7-.7c-1.4-1.4-3.4-2.9-5.7-3,.2.5,1.4,2.2,2,3.3a17.93,17.93,0,0,1,1.6,3.7,51.34,51.34,0,0,1,1.3,9"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M31.1,28.8a44.35,44.35,0,0,1,1.4-9.1c1.1-3.7,3.4-6,3.6-6.9A7.56,7.56,0,0,0,32,14.4c-.7.5-2.6,2.1-2.7,2.8a7.09,7.09,0,0,1,3.1-1c-.6,1.7-1.4,2.4-2.5,5.8a38.28,38.28,0,0,0-1.3,6.9Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,35.2a8.74,8.74,0,0,1,1,.8,5.41,5.41,0,0,1,.9,2.7h5c.3-2.1.7-3.1,2.6-3.8l.3-.1c.4-.2.1,0,.3-.2H14.9Z"
                          transform="translate(-5 -4.9)"/>
                    <polygon className="large-select-1" points="12.8 40.7 18.2 40.7 18.2 37 12.8 37 12.8 40.7"/>
                    <polygon className="large-select-1" points="12 36.3 19.1 36.3 19.1 34.6 12 34.6 12 36.3"/>
                    <path className="large-select-1" d="M9.9,36.3c1.4.3,2.7-.3,2.4-1.8H9.9Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M31.1,36.3V34.5H28.7c-.4,1.4,1,2.1,2.4,1.8"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,13.7c1.1-.3,2.7-1.3,3.5-1.4a30.11,30.11,0,0,1-.9,9.5c-1.1,3.9-2.1,4.5-3.3,7h2.8a17.91,17.91,0,0,0,1.2-2.4,15.78,15.78,0,0,1,.9-2.6,25.58,25.58,0,0,1,.9,2.6c.3.8,1,1.7,1.2,2.4h2.9c-1.1-2.4-2.2-3.1-3.3-7.1a33,33,0,0,1-.9-9.5c1.1.2,2.3,1.2,3.6,1.4a8.45,8.45,0,0,0-1.5-1.8,8.86,8.86,0,0,1-2.2-4.3,16.65,16.65,0,0,0-.6-2.6c-.8,1.5,0,4.2-3.6,7.7a3.38,3.38,0,0,0-.7,1.1"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,13.7c1.1-.3,2.7-1.3,3.5-1.4a30.11,30.11,0,0,1-.9,9.5c-1.1,3.9-2.1,4.5-3.3,7h2.8a17.91,17.91,0,0,0,1.2-2.4,15.78,15.78,0,0,1,.9-2.6c.2.3.7,2.1.9,2.6.3.8,1,1.7,1.2,2.4h2.9c-1.1-2.4-2.2-3.1-3.3-7.1a33,33,0,0,1-.9-9.5c1.1.2,2.2,1.2,3.6,1.4a8.45,8.45,0,0,0-1.5-1.8,8.86,8.86,0,0,1-2.2-4.3,16.65,16.65,0,0,0-.6-2.6c-.8,1.5,0,4.2-3.6,7.7A3.38,3.38,0,0,0,16.2,13.7Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M9.9,28.8h2.6a30.52,30.52,0,0,0-3-11.2,4.59,4.59,0,0,1-.7-1.4c1.5,0,2.4.9,3.1,1-.2-.2-.3-.5-.5-.7l-.7-.7c-1.4-1.4-3.4-2.9-5.7-3,.2.5,1.4,2.2,2,3.3a17.93,17.93,0,0,1,1.6,3.7,51.34,51.34,0,0,1,1.3,9"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M9.9,28.8h2.6a30.52,30.52,0,0,0-3-11.2,4.59,4.59,0,0,1-.7-1.4c1.5,0,2.4.9,3.1,1-.2-.2-.3-.5-.5-.7l-.7-.7c-1.4-1.4-3.4-2.9-5.7-3,.2.5,1.4,2.2,2,3.3a17.93,17.93,0,0,1,1.6,3.7A51.34,51.34,0,0,1,9.9,28.8Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M31.1,28.8a44.35,44.35,0,0,1,1.4-9.1c1.1-3.7,3.4-6,3.6-6.9A7.56,7.56,0,0,0,32,14.4c-.7.5-2.6,2.1-2.7,2.8a7.09,7.09,0,0,1,3.1-1c-.6,1.7-1.4,2.4-2.5,5.8a38.28,38.28,0,0,0-1.3,6.9Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M31.1,28.8a44.35,44.35,0,0,1,1.4-9.1c1.1-3.7,3.4-6,3.6-6.9A7.56,7.56,0,0,0,32,14.4c-.7.5-2.6,2.1-2.7,2.8a7.09,7.09,0,0,1,3.1-1c-.6,1.7-1.4,2.4-2.5,5.7a38.28,38.28,0,0,0-1.3,6.9Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,35.2a8.74,8.74,0,0,1,1,.8,5.41,5.41,0,0,1,.9,2.7h5c.3-2.1.7-3.1,2.6-3.8l.3-.1c.4-.2.1,0,.3-.2H14.9Z"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1"
                          d="M16.2,35.2a8.74,8.74,0,0,1,1,.8,5.41,5.41,0,0,1,.9,2.7h5c.3-2.1.7-3.1,2.6-3.8l.3-.1c.4-.2.1,0,.3-.2H14.9Z"
                          transform="translate(-5 -4.9)"/>
                    <polygon className="large-select-1" points="12.8 40.7 18.2 40.7 18.2 37 12.8 37 12.8 40.7"/>
                    <rect className="large-select-1" x="12.7" y="37" width="5.5" height="3.8"/>
                    <polygon className="large-select-1" points="12.8 40.7 18.2 40.7 18.2 37 12.8 37 12.8 40.7"/>
                    <polygon className="large-select-1" points="12 36.3 19.1 36.3 19.1 34.6 12 34.6 12 36.3"/>
                    <rect className="large-select-1" x="11.9" y="34.6" width="7.3" height="1.7"/>
                    <polygon className="large-select-1" points="12 36.3 19.1 36.3 19.1 34.6 12 34.6 12 36.3"/>
                    <path className="large-select-1" d="M32.6,30.3H8v-.8H32.6Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M32.4,33.6H8.5V33H32.4Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M31.3,32.1H9.3v-.8h22Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M9.9,36.3c1.4.3,2.7-.3,2.4-1.8H9.9Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M9.9,36.3c1.4.3,2.7-.3,2.4-1.8H9.9Z" transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M31.1,36.3V34.5H28.7c-.4,1.4,1,2.1,2.4,1.8"
                          transform="translate(-5 -4.9)"/>
                    <path className="large-select-1" d="M31.1,36.3V34.5H28.7C28.3,35.9,29.7,36.6,31.1,36.3Z"
                          transform="translate(-5 -4.9)"/>
                </svg>

            </div>
          </div>
        )
    //}
}

export default BigLogoLoader
